<template>
    <div class="pt-100 pb-70 gray-bg">
        <div class="container">
            <div class="section-title">
                <h2>TripAfrica, c'est quoi ?</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class="bx bx-conversation"></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            <!-- <router-link to="/services-details">Des séjours, tout compris</router-link> -->
                            Des séjours, tout compris
                        </h3>
                        <p>Des séjours préconçus avec soin, comprenant transport, hébergement, restauration et
                            activités. Craquez pour votre coup de coeur, il ne vous reste plus qu’à en profiter !</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class="bx bx-award"></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            <!-- <router-link to="/services-details">Un standing élevé</router-link> -->
                            Un standing élevé
                        </h3>
                        <p>Large choix de formules, plusieurs propositions de moyens de paiements, prestations de
                            qualités, assurance voyage comprise et paiement sécurisé.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class="bx bxs-megaphone"></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            <!-- <router-link to="/services-details">Des offres de booking</router-link> -->
                            Des offres de booking
                        </h3>
                        <p>Vous souhaitez réserver un appartement ? Un hôtel ? Un trajet en bus ? Notre site vous offre
                            également ce service. Retrouvez nos différentes offres partenaires.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="service-card-one bg-white center">
                        <div class="icon">
                            <i class='bx bx-mobile-alt'></i>
                            <i class='bx bxs-badge-check check-icon'></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Accompagnement pas à pas</router-link>
                        </h3>
                        <p>Besoins d’aide, de renseignements ? Nous pouvons vous accompagner lors de votre réservation.
                            N’hésitez pas, nous nous ferons le plaisir de vous accompagner.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurServices'
}
</script>