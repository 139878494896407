<script lang="js" setup>
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import { useUserStore } from '@/store/userStore';


/** @vars */
const active = ref(false)
const isSticky = ref(false)
const userStore = useUserStore()
const button_active_state = ref(false)
const { isAuth } = storeToRefs(userStore)



/** @hooks */
onMounted(() => {
  window.addEventListener("scroll", () => {
    let scrollPos = window.scrollY;
    if (scrollPos >= 100) {
      isSticky.value = true;
    } else {
      isSticky.value = false;
    }
  });

})
</script>


<template>
  <div>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
      <div class="bionix-nav">
        <div class="container">
          <nav class="navbar navbar-expand-lg navbar-light">
            <router-link class="navbar-brand" to="/">
              <img src="@/assets/images/logo/logo-tripafrica-with-name.png" alt="logo" width="200" />
            </router-link>

            <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
              v-bind:class="{ active: button_active_state }" v-on:click="button_active_state = !button_active_state">
              <span class="icon-bar top-bar"></span>
              <span class="icon-bar middle-bar"></span>
              <span class="icon-bar bottom-bar"></span>
            </div>

            <div class="collapse navbar-collapse" :class="{ show: active }">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <router-link to="/" class="nav-link">Accueil</router-link>
                </li>

                <li class="nav-item">
                  <router-link to="/trips" class="nav-link">Nos Séjours</router-link>
                </li>


                <li class="nav-item">
                  <router-link to="/about-us" class="nav-link">À Propos</router-link>
                </li>


                <li class="nav-item">
                  <router-link to="/contact" class="nav-link">Contact</router-link>
                </li>


                <li class="nav-item">
                  <router-link :to="isAuth ? '/profile' : '/login'" class="nav-link">Mon Compte</router-link>
                </li>

              </ul>
            </div>

          </nav>
        </div>
      </div>
    </div>

  </div>
</template>
