<template>
  <div class="choose-area-two pb-100 pt-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="choose-content">
            <div class="section-title text-left">
              <span class="sub-title">À propos de nous</span>
              <h2>Enjoy the real Africa!</h2>

              <p>
                Envie de vous évader le temps d’un week-end, sans avoir à vous préoccuper de la moindre contrariété ? De
                vous laisser séduire par des formules riches en découvertes, qui vous laisseront des souvenirs
                inoubliables ?
              </p>

              <p>

                TripAfrica vous offre des formules de rêve, comprenant le transport, l’hébergement, la restauration
                ainsi que plusieurs activités, afin que vous puissiez, en seulement quelques clics, réserver le séjour
                parfait. Partez l’esprit serein, nous nous occupons de tout.
              </p>

              <p>
                Nous mettons régulièrement nos formules à jour, afin de vous proposer une large gamme de propositions,
                Et pour que vous puissiez renouveler l’expérience régulièrement, sans jamais vous ennuyer.
              </p>
            </div>

            <div class="choose-btn">
              <router-link class="default-btn" to="/about-us">
                voir plus
                <span></span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="choose-image">
            <img src="../../assets/images/banner/tripAfrica-banner-4.webp" alt="image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EngagingNew",
};
</script>