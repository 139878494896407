<script lang="js" setup>
import dayjs from "dayjs"
import { setImage } from "@/utils"
import { storeToRefs } from "pinia"
import { ref, onMounted, computed } from "vue"
import { useRoute } from "vue-router"
import { isCritical } from "../../../utils"
import { useArticleStore } from "@/store/articleStore"


const slide = ref(0)
const route = useRoute()
const article = ref({ id: 1 })
const { loadArticle } = useArticleStore()
const { loadingDetails } = storeToRefs(useArticleStore())

const extractDepartureInfo = (departure) => {
  return departure.split(" ")
}

const departureDates = computed(() => {
  return article.value?.departures?.filter((d) => {
    console.log(dayjs(d).diff(dayjs(), 'day'))
    if (dayjs(d).diff(dayjs(), 'day') > 4) {
      return d
    }
  })
})



onMounted(() => {
  loadArticle(route.params?.id)
    .then((res) => article.value = res)
    .catch((err) => console.log(err))
})

</script>


<template>
  <div class="product-details-area ptb-100">

    <div class="container page d-flex justify-content-center" v-if="loadingDetails">
      <div>
        <b-spinner small label="Small Spinner" variant="success" class="mx-1" type="grow"></b-spinner>
        <b-spinner small label="Small Spinner" variant="success" class="mx-1" type="grow"></b-spinner>
        <b-spinner small label="Small Spinner" variant="success" class="mx-1" type="grow"></b-spinner>
      </div>
    </div>

    <div class="container" v-else>
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="product-details-image">
            <!-- <img :src="setImage(article?.photo)" alt="image" /> -->
            <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab"
              img-width="100%" img-height="500">
              <b-carousel-slide v-for="media in article?.medias" :key="media?.id" :img-src="setImage(media.media)">
              </b-carousel-slide>
            </b-carousel>
          </div>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="product-details-desc">
            <h3>{{ article?.name }}</h3>

            <h6>{{ article?.destination }}</h6>
            <h6>{{ article?.duration }}</h6>

            <div class="price">
              <span class="new-price">{{ article?.price }} FCFA</span>
            </div>

            <p>{{ article?.description }}</p>


            <div v-if="article?.current_invoice && article?.current_invoice?.status == 'incomplete'">

              <div class="alert alert-danger d-flex align-items-center" role="alert"
                v-if="isCritical(article?.current_invoice?.departure)">
                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                  <use xlink:href="#exclamation-triangle-fill" />
                </svg>
                <div>
                  <p class="m-0">Le delai de paiment pour ce séjour est depassé.</p>
                  <p class="m-0"><router-link to="/contact">Veuillez contacter le service client pour plus
                      d'indications</router-link></p>
                </div>
              </div>

              <div v-else class="alert alert-info d-flex align-items-center" role="alert">
                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                  <use xlink:href="#exclamation-triangle-fill" />
                </svg>
                <div>
                  <p class="m-0">vous avez un payement non terminé pour ce séjour</p>
                  <p class="m-0">montant avancé: {{ article?.current_invoice?.amount }} FCFA</p>
                  <p class="m-0">reste à payer: {{ article?.current_invoice?.remaining_amount }} FCFA</p>
                </div>
              </div>


              <router-link class="default-btn" v-if="!isCritical(article?.current_invoice?.departure)"
                :to="{ name: 'checkout', params: { trip: article?.id }, query: { invoice: article?.current_invoice?.id } }">
                continuer le payement
              </router-link>

            </div>

            <div v-else>
              <div class="alert alert-danger d-flex align-items-center" role="alert"
                v-if="article?.departures ? isCritical(article?.departures[article?.departures?.length - 1]) : true">
                <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                  <use xlink:href="#exclamation-triangle-fill" />
                </svg>
                <div>
                  <p class="m-0">Le delai de paiment pour ce séjour est depassé.</p>
                  <p class="m-0">
                    <router-link to="/contact">Veuillez contacter le service client pour plus
                      d'indications</router-link>
                  </p>
                </div>
              </div>
              <div v-else>
                <router-link class="default-btn" :to="{ name: 'checkout', params: { trip: article?.slug } }">Reservez
                  maintenant</router-link>
              </div>
            </div>


          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="products-details-tab">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="tab_content">
                  <b-tabs content-class="mt-3">

                    <b-tab title="Programme">
                      <div class="tabs_item">
                        <div class="products-details-tab-content">
                          <ul class="list-group list-group-numbered">
                            <li class="list-group-item" v-for="program in article?.program" :key="program">
                              <div class="ms-2 me-auto">
                                <div class="fw-bold">{{ program?.day }}</div>
                                <ul>
                                  <li v-for="activity in program?.activities" :key="activity">{{ activity }}</li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab title="Informations suplementaires">
                      <h5>Eléments inclus dans le package</h5>
                      <ol class="list-group list-group-numbered">
                        <li class="list-group-item" v-for="item in article?.packages" :key="item">{{ item }}</li>
                      </ol>
                    </b-tab>

                    <b-tab title="Dates & Horaires">
                      <h5>Date de début du séjour</h5>
                      <div class="tabs_item">
                        <div class="products-details-tab-content">
                          <ul class="additional-information">
                            <li v-for="departure in departureDates" :key="departure">
                              <span>
                                {{ dayjs(extractDepartureInfo(departure)[0], 'YYYY-MMM-DD').format("ddd DD MMM YYYY") }}
                                .</span>
                              {{ extractDepartureInfo(departure)[1] }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
