<script lang="js" setup>
import { ref } from "vue"
import * as yup from 'yup';
import { storeToRefs } from "pinia"
import { useRouter, useRoute } from "vue-router"
import { useUserStore } from "@/store/userStore"
import { createToaster } from "@meforma/vue-toaster";
import { Field, Form, ErrorMessage } from 'vee-validate';



const route = useRoute()
const router = useRouter()
const showPassword = ref(false)
const userStore = useUserStore()

const { login } = userStore
const toaster = createToaster();
const { loading } = storeToRefs(userStore)

const schema = yup.object({
    login: yup.string().required(),
    password: yup.string().required().min(8),
});

const authLogin = (values) => {
    login(values)
        .then(() => {
            if (route.query.next) router.replace({ path: route.query.next })
            else router.push('/')
            toaster.success("content de vous revoir");
        })
        .catch((err) => {
            if (err.status == 403) {
                toaster.warning("email ou mot de passe incorrect.");
            } else {
                toaster.error("Une erreur est survenue lors du proccessus d'authentication.");
            }
        })
}

</script>

<template>
    <div class="ptb-100">
        <div class="container">
            <div class="row align items center justify-content-center">
                <div class="col-lg-6">
                    <div class="form-content">
                        <Form @submit="authLogin" :validation-schema="schema">
                            <div class="form-group">
                                <label>Email</label>
                                <Field type="email" name="login" class="form-control" id="your-email"
                                    placeholder="Votre mot email" />
                                <ErrorMessage name="login" />
                            </div>
                            <div class="form-group">
                                <label>Mot de passe</label>
                                <Field :type="showPassword ? 'text' : 'password'" name="password" class="form-control"
                                    id="your-password" placeholder="mot de passe ****" />
                                <ErrorMessage name="password" />
                            </div>

                            <div class="form-group">
                                <b-form-checkbox id="checkbox-1" v-model="showPassword" name="checkbox-1"
                                    value="accepted" unchecked-value="not_accepted">
                                    Afficher le mot de passe
                                </b-form-checkbox>
                            </div>

                            <button type="submit" class="default-btn" :disabled="loading">Se
                                connecter</button>
                            <div class="d-flex justify-content-center mt-2" v-if="loading">
                                <b-spinner small label="Small Spinner" variant="success" class="mx-1"
                                    type="grow"></b-spinner>
                                <b-spinner small label="Small Spinner" variant="success" class="mx-1"
                                    type="grow"></b-spinner>
                                <b-spinner small label="Small Spinner" variant="success" class="mx-1"
                                    type="grow"></b-spinner>
                            </div>
                        </Form>

                        <div class="form-footer">
                            <p>pas encore de compte?
                                <router-link class="form-link"
                                    :to="{ path: '/signup', query: { ...$route.query } }">Créer
                                    un compte</router-link>
                            </p>
                            <p>
                                <router-link class="form-link"
                                    :to="{ path: '/password-request', query: { ...$route.query } }">
                                    mot de passe oublier
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>