import api from "..";

const Transaction = {
  init: (oparator, data) =>
    api("POST", `/payments/${oparator}/init`, data, `initialisation ${oparator} payement`),
  check: (oparator, data) =>
    api("POST", `/payments/${oparator}/check`, data, `checking ${oparator} payement`),
    get: (query, data) =>
		api("GET", `/transactions?${query}`, "", `${data}`),
};

export default Transaction;
