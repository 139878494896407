<script lang="js" setup>
import dayjs from "dayjs"
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { ref, onMounted } from 'vue';
import Invoice from "@/api/models/Invoice";
import { useRouter } from 'vue-router';

import { useUserStore } from '@/store/userStore';
import { createToaster } from "@meforma/vue-toaster";
import { useInvoiceStore } from '@/store/invoiceStore';
import { Field, Form, ErrorMessage } from 'vee-validate';
import VLoader from '@components/trip-africa/VLoader.vue';

/** @vars */
// const route = useRoute()
const minDate = new Date();
const toaster = createToaster();
const userStore = useUserStore()
const invoinceStore = useInvoiceStore()

const schema = yup.object({
  first_name: yup.string().required("votre prenom est requis"),
  last_name: yup.string().required("votre nom est requis"),
  username: yup.string().required("votre nom d'utilisateur  est requis"),
  birthday: yup.date().required("votre date de naissance est requise").max(minDate, "votre date de naissance semble incorrecte"),
  email: yup.string().required("votre email est requis").email("votre email n'est pas valide"),
  phone: yup.number().required("votre numéro de téléphone est requis").min(9, "votre numéro de téléphone n'est pas valide"),
});

const router = useRouter()
const formAction = ref('view') // view|edit
const { logout, updateUser } = userStore
const { loadInvoices } = invoinceStore
const { getUser, loading } = storeToRefs(userStore)
const userData = ref(getUser.value)
const { invoices, loadingInvoices } = storeToRefs(invoinceStore)

/** @func */
const logoutAction = () => {
  logout()
  router.push('/')
  setFormAction()
}

const updateUserAction = (values) => {
  updateUser(values)
    .then(() => {
      toaster.success("information enregistrées");
      setFormAction('view')
    })
    .catch((err) => {
      console.log(err)
      toaster.error("une erreur est survenue");
    })
}

const setFormAction = (action = 'view') => {
  formAction.value = action
}

const downloadInvoice = (invoiceId) => {
  toaster.warning("le téléchargement débutera dans quelque instant..");
  const API_URL = process.env.VUE_APP_BASE_API_URL

  Invoice.download(invoiceId)
    .then((res) => {
      console.log(res)
      const filePath = res.data.file_link;
      window.open(`${API_URL}/${filePath}`, "_blank");
    })
    .catch((err) => {
      console.log(err)
      toaster.error("une erreur est survenue  lors du telechargement de la facture !");
    })


}

const isCritical = (date) => {
  const currentDate = dayjs();
  const dateToCompare = dayjs(date)
  const timeDifference = dateToCompare.diff(currentDate, 'day')

  if (timeDifference <= 4) return true
  else return false
}

const getPaymentDelayDate = (date) => {
  return dayjs(date).subtract(4, 'days')
}

// const toggleTablesView = (tab) => {
//   router.push({
//     path: router.path.toString,
//     query: {
//       tab: tab
//     }
//   })
// }

onMounted(() => {
  loadInvoices()
    .then((res) => console.log(res))
    .catch((err) => console.log(err))
})
</script>


<template>
  <div class="ptb-100">
    <div class="container">
      <div>
        <b-card no-body>
          <b-tabs pills card active-nav-item-class="text-success">
            <b-tab title="Mon profile" :active="false">

              <div class="d-flex justify-content-center">

                <div class="form-content col-12">
                  <div class="form-header">
                    <div class="d-flex justify-content-between align-items-center">
                      <h3>Votre profile</h3>
                      <span class="isclickable" @click="setFormAction('edit')"><i class="ri-edit-2-fill"></i></span>
                    </div>
                  </div>

                  <Form :initial-values="userData" @submit="updateUserAction" :validation-schema="schema">
                    <div class="form-group">
                      <label>Prenom</label>
                      <Field type="text" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="first_name" class="form-control" id="first-name" placeholder="prenom" />
                      <ErrorMessage name="login" />
                    </div>

                    <div class="form-group">
                      <label>Nom</label>
                      <Field type="text" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="last_name" class="form-control" id="last-name" placeholder="nom" />
                      <ErrorMessage name="login" />
                    </div>

                    <div class="form-group">
                      <label>Email</label>
                      <Field type="email" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="email" class="form-control" id="your-email" placeholder="email" />
                      <ErrorMessage name="login" />
                    </div>

                    <div class="form-group">
                      <label>Téléphone</label>
                      <Field type="text" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="phone" class="form-control" id="your-email" placeholder="No téléphone" />
                      <ErrorMessage name="login" />
                    </div>


                    <div class="form-group">
                      <label>Date de naissance</label>
                      <Field type="date" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="birthday" class="form-control" id="your-email" placeholder="" />
                      <ErrorMessage name="birthday" />
                    </div>

                    <div class="form-group">
                      <label>Adresse</label>
                      <Field type="text" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="address" class="form-control" id="your-email" placeholder="adresse" />
                      <ErrorMessage name="login" />
                    </div>


                    <div class="form-group">
                      <label>Bio</label>
                      <Field type="text" :class="formAction == 'view' && 'no-borders'" :disabled="formAction == 'view'"
                        name="bio" class="form-control" id="your-email" placeholder="Bio" />
                      <ErrorMessage name="login" />
                    </div>



                    <button type="submit" class="default-btn" v-if="formAction == 'edit'">Sauvegarder</button>
                    <button v-else type="submit" style="background: #fa7070" class="default-btn mt-2"
                      @click="logoutAction">Déconnection</button>
                    <div v-if="loading">
                      <VLoader />
                    </div>
                  </Form>

                </div>

              </div>

            </b-tab>

            <b-tab title="Mes reservations" :active="true">

              <div class="col-12">
                <h4>Mes Reservations</h4>
                <div class="table-responsive">
                  <table class="table caption-top" v-if="!loadingInvoices && invoices.length > 0">
                    <thead>
                      <tr>
                        <th scope="col">Sejour</th>
                        <th scope="col">Date de départ</th>
                        <th scope="col">Montant</th>
                        <th scope="col">Solde</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Délai de paiement</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="invoice in invoices" :key="invoice.id">
                        <th scope="row">
                          <router-link :to="{ name: 'trip-detail', params: { id: invoice?.article_id ?? 1 } }"
                            class="text-success">
                            {{ invoice?.article?.name }}
                          </router-link>
                        </th>
                        <td>{{ dayjs(invoice?.departure, 'YYYY-MMM-DD').format("DD MMM YYYY") }} </td>
                        <td>{{ invoice?.amount }} FCFA</td>
                        <td>{{ invoice?.remaining_amount }} FCFA</td>
                        <td>
                          <b-badge :variant="invoice?.status == 'incomplete' ? 'warning' : 'success '">
                            {{ invoice?.status }}
                          </b-badge>
                        </td>
                        <td
                          :class="[isCritical(getPaymentDelayDate(invoice?.departure)) ? 'text-danger' : 'text-warning']">
                          {{ getPaymentDelayDate(invoice?.departure).format("DD MMM YYYY") }}
                        </td>
                        <td>
                          <routerLink :to="{ name: 'invoice-details', params: { invoice: invoice?.id } }"
                            class="btn btn-success btn-sm">Details</routerLink>
                          <button class="btn btn-success btn-sm mx-1" @click="downloadInvoice(invoice?.id)"><i
                              class="ri-download-2-fill"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p v-if="!loadingInvoices && invoices.length == 0">Aucune facture trouvée</p>

                <div v-if="loadingInvoices">
                  <VLoader />
                </div>
              </div>

            </b-tab>

          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
.no-borders {
  border: unset !important;
}

.isclickable {
  cursor: pointer;
}

.nav-link {
  color: #000 !important
}
</style>