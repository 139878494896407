<script lang="js" setup>
import { ref } from "vue"
import * as yup from 'yup';
import Auth from "@/api/models/Auth";
import { useRoute, useRouter } from "vue-router"
import PageTitle from '@components/Common/PageTitle'
import { createToaster } from "@meforma/vue-toaster";
import VLoader from '@components/trip-africa/VLoader.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';

/** @data */
const user = ref(null)
const loading = ref(false)
const route = useRoute()
const router = useRouter()
const action = ref('token')
const hasErrors = ref(false)
const showPassword = ref(false)
const validationErrors = ref({})
const toaster = createToaster();
const schema = yup.object({
  login: yup.string().required("ce champs est requis"),
});

const schema2 = yup.object({
  code: yup.string().required("ce champs est requis"),
  new_password: yup.string().required("ce champs est requis")
});

/** @func */
const sendOTP = (values) => {

  console.log(values)
  loading.value = true
  hasErrors.value = false

  Auth.getToken(values)
    .then((res) => {
      console.log(res)
      action.value = 'reset'
      user.value = res.data.user
    })
    .finally(() => loading.value = false)
    .catch((err) => {
      console.log(err)
      toaster.error("une erreur est survenue !")
      if (err.status == 422) {
        hasErrors.value = true
        validationErrors.value = err.data.data
      }
    })
}
const reset = (values) => {

  console.log(values)
  loading.value = true
  hasErrors.value = false

  Auth.resetPassword(user.value?.id, values)
    .then((res) => {
      console.log(res)
      toaster.success("mise à jour effectuée !")
      router.push({ path: '/login', query: { ...route.query } })
    })
    .finally(() => loading.value = false)
    .catch((err) => {
      console.log(err)
      toaster.error("une erreur est survenue !")
      if (err.status == 422) {
        hasErrors.value = true
        validationErrors.value = err.data.data
      }

    })
}


</script>

<template>
  <div>
    <PageTitle pageTitle="mot de passe oublier" />
    <div class="ptb-100">
      <div class="container">
        <div class="row align items center justify-content-center">
          <div class="col-lg-6">
            <div class="form-content">

              <div key="1" v-if="action == 'token'">

                <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="hasErrors">
                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                    <use xlink:href="#exclamation-triangle-fill" />
                  </svg>
                  <div>
                    <ul>
                      <li v-for="error in validationErrors" :key="error">{{ error[0] }}</li>
                    </ul>
                  </div>
                </div>

                <Form @submit="sendOTP" :validation-schema="schema">
                  <div class="form-group">
                    <label>login</label>
                    <Field type="email" name="login" class="form-control" id="your-email"
                      placeholder="email, numero de téléphone ou nom d'utilisateur" />
                    <ErrorMessage name="login" class="text-danger" />
                  </div>
                  <button type="submit" class="default-btn" :disabled="loading">Envoyer</button>
                  <div class="d-flex justify-content-center mt-2" v-if="loading">
                    <VLoader />
                  </div>
                </Form>
              </div>


              <div key="2" v-else>

                <div class="alert alert-info d-flex align-items-center" role="alert">
                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                    <use xlink:href="#exclamation-triangle-fill" />
                  </svg>
                  <div>
                    <p>le code vous a été envoyer à l'address: {{ user.email }}. ce code est valide 10 minutes</p>
                  </div>
                </div>

                <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="hasErrors">
                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                    <use xlink:href="#exclamation-triangle-fill" />
                  </svg>
                  <div>
                    <ul>
                      <li v-for="error in validationErrors" :key="error">{{ error[0] }}</li>
                    </ul>
                  </div>
                </div>

                <Form @submit="reset" :validation-schema="schema2" autocomplete="off">
                  <div class="form-group">
                    <label>code</label>
                    <Field autocomplete="off" type="text" name="code" class="form-control" id="your-code"
                      placeholder="code " />
                    <ErrorMessage name="code" class="text-danger" />
                  </div>
                  <div class="form-group">
                    <label>nouveau mot de passe</label>
                    <Field autocomplete="off" :type="showPassword ? 'text' : 'password'" as="input" name="new_password"
                      class="form-control" id="your-token" placeholder="*********" />
                    <ErrorMessage name="new_password" class="text-danger" />
                  </div>
                  <div class="form-group">
                    <b-form-checkbox id="checkbox-1" v-model="showPassword" name="checkbox-1" value="accepted"
                      unchecked-value="not_accepted">
                      Afficher le mot de passe
                    </b-form-checkbox>
                  </div>
                  <button type="submit" class="default-btn" :disabled="loading">Envoyer</button>
                  <div class="d-flex justify-content-center mt-2" v-if="loading">
                    <VLoader />
                  </div>
                </Form>
              </div>

              <div class="form-footer">
                <p>deja un compte?
                  <router-link class="form-link" :to="{ path: '/login', query: { ...$route.query } }">se
                    connecter
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
