<script lang="js" setup>
import { ref } from "vue"
import * as yup from 'yup';
import Contact from "@/api/models/Contact";
import { createToaster } from "@meforma/vue-toaster";
import { Field, Form, ErrorMessage } from 'vee-validate';
import VLoader from '@components/trip-africa/VLoader.vue';

/** @data */
const isLoading = ref(false)
const toaster = createToaster();

const schema = yup.object({
    name: yup.string().required("Votre nom est requis"),
    email: yup.string().email("Adresse email non valide").required("votre nom est requis"),
    phone: yup.string().required("Votre numéro de téléphone est requis"),
    subject: yup.string().required("Le sujet est requis"),
    message: yup.string().required("Le message est requis"),
});


/** @func */

const send = (values) => {
    console.log(values)
    isLoading.value = true

    Contact.send(values)
        .then((res) => {
            console.log(res)
            toaster.error("votre requête a été envoyée!")
        })
        .catch((res) => {
            console.log(res)
            toaster.error("une erreur est survenue reessayez !")
        })
        .finally(() => isLoading.value = false)
}


</script>

<template>
    <div class="contact-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class="flaticon-email"></i>
                        </div>

                        <h3>Email</h3>
                        <p><a href="mailto:contact@tripafrica.net">contact@tripafrica.net</a></p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class="flaticon-marker"></i>
                        </div>

                        <h3>Où nous trouver</h3>
                        <p>Douala, Cameroun</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="contact-info-box">
                        <div class="icon">
                            <i class="flaticon-phone-call"></i>
                        </div>

                        <h3>Téléphone</h3>
                        <p><a href="tel:+237242952138">+237 242 952 138</a></p>
                    </div>
                </div>
            </div>

            <div class="section-title">
                <span class="sub-title">Contactez nous</span>
                <h2>Une préoccupation? contactez-nous</h2>
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua.</p> -->
            </div>

            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="contact-image">
                        <img src="../../assets/images/contact.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-8 col-md-8">
                    <div class="contact-form">
                        <Form id="contactForm" :validation-schema="schema" @submit="send">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <Field type="text" name="name" id="name" class="form-control" required
                                            placeholder="Nom et Prénom" />
                                        <ErrorMessage class="text-danger" name="name" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <Field type="email" name="email" id="email" class="form-control" required
                                            placeholder="Email" />
                                        <ErrorMessage class="text-danger" name="email" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <Field type="text" name="phone" id="phone_number" required class="form-control"
                                            placeholder="Téléphone" />
                                        <ErrorMessage class="text-danger" name="phone" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <Field type="text" name="subject" id="msg_subject" class="form-control" required
                                            placeholder="Sujet" />
                                        <ErrorMessage class="text-danger" name="subject" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <Field as="textarea" name="message" class="form-control" id="message" cols="30"
                                            rows="5" required placeholder="Votre message ici..."></Field>
                                        <ErrorMessage class="text-danger" name="message" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button :disabled="isLoading" type="submit" class="default-btn">
                                        ENVOYER
                                        <span></span>
                                    </button>

                                    <div v-if="isLoading">
                                        <VLoader />
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
