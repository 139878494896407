<script lang="js" setup>
import { ref } from "vue"
import { onMounted } from "vue";
import { storeToRefs } from "pinia"
import "vue3-carousel/dist/carousel.css";
import { useArticleStore } from "@/store/articleStore"
import LTripCard from "@components/trip-africa/trips/LTripCard"
import VSkeletonLine from "@components/trip-africa/skeleton/VSkeletonLine"
import VSkeletonBlock from "@components/trip-africa/skeleton/VSkeletonBlock"



/** @data */
const tripList = ref([])
const articleStore = useArticleStore()
const { articles, isLoading } = storeToRefs(articleStore)


onMounted(() => {
  articleStore.loadArticles()
    .then(() => {
      tripList.value = [...articles.value]
      tripList.value.length = 3
    })
    .catch((err) => console.log(err))
})

</script>


<template>
  <div class="works-area ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>Nos Séjours</h2>
        <p>Découvrez nos différentes offres, et trouvez celle qui est faite pour vous ! Coup de cœur garanti.</p>
      </div>

      <div class="row" v-if="!isLoading">
        <div class="col-lg-4 col-sm-6" v-for="trip in tripList" :key="trip.id">
          <LTripCard :trip="trip" />
        </div>
      </div>

      <div class="row" v-else>
        <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in new Array(3)" :key="item">
          <VSkeletonBlock class="my-2" width="100%" height="300px" />
          <VSkeletonLine width="90%" />
          <VSkeletonLine class="mt-1" width="70%" />
        </div>
      </div>

      <div class="more-work">
        <router-link to="/trips" class="default-btn">
          Voir tous les séjours <span></span>
        </router-link>
      </div>
    </div>
  </div>
</template>