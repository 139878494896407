import "dayjs/locale/fr";
import axios from "axios";
import dayjs from "dayjs";
import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
import VueAxios from "vue-axios";
import { createPinia } from 'pinia'
import 'remixicon/fonts/remixicon.css'
import Toaster from "@meforma/vue-toaster";
import BootstrapVueNext from "bootstrap-vue-next";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "./assets/custom.scss";


dayjs.locale("fr");
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate);
const app = createApp(App).use(router);


app.use(BootstrapVueNext).use(Toaster).use(pinia)
app.use(VueAxios, axios).mount("#app");
