<template>
  <div>
    <div class="seo-banner-slider">
      <Carousel :autoplay="5000" :settings="settings" :wrap-around="true">
        <Slide v-for="slide in carouselItems" :key="slide.id">
          <div class="seo-banner">
            <div class="d-table">
              <div class="d-table-cell">
                <div class="container mt-50">
                  <div class="row align-items-center">
                    <div class="col-lg-6">
                      <div class="banner-content">
                        <h1>{{ slide.heading }}</h1>
                        <p>{{ slide.desc }}</p>

                        <div class="banner-btn">
                          <router-link to="/trips" class="default-btn mr-3">
                            Reservez maintenant <span></span>
                          </router-link>

                          <!-- <a class="video-btn popup-youtube" v-on:click="isPopupMethod(isPopup)"
                            style="cursor: pointer">
                            <i class="flaticon-play-button"></i>
                            Play Video
                          </a> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="seo-banner-image animate-tb">
                        <img :src="slide.image" alt="image" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>

    <div class="popup-video" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "HomeBanner",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselItems: [
      {
        id: 1,
        heading: "Payez en plusieurs fois avant de voyager !",
        desc: "Envie de profiter des miracles de la nature? Offrez-vous des séjours inoubliables.",
        image: require("../../assets/images/banner/tripAfrica-banner-1.webp"),
      },
      {
        id: 2,
        heading: "Offrez-vous un séjour de rêve en payant en plusieurs fois!",
        desc: "Envie de vous épanouir et de découvrir de nouveaux paysages? Découvrez nos séjours en pension complète.",
        image: require("../../assets/images/banner/tripAfrica-banner-2.webp"),
      },
      {
        id: 3,
        heading: "Découvrez nos destinations et payez en plusieurs fois!",
        desc: "Envie d’évasion le temps d’un week-end ? Retrouvez nos destinations comprenant transport, hébergement, restauration, activités, visites   guidées, assurance voyage",
        image: require("../../assets/images/banner/tripAfrica-banner-3.webp"),
      },
    ],

    isPopup: false,
  }),
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
});
</script>