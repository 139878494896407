<template>
    <div class="feedback-area feedback-area-two ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Des clients satisfaits</span>
                <h2 style="text-transform:lowercase">Ils ont essayé et ont adoré</h2>
            </div>
        </div>

        <FeedbackCarouselTwo />
    </div>
</template>

<script>
import FeedbackCarouselTwo from '../Carousels/FeedbackCarouselTwo'
export default {
    name: 'SomeLovelyFeedbackTwo',
    components: {
        FeedbackCarouselTwo
    }
}
</script>