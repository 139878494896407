import dayjs from "dayjs"

function setImage(image) {
	const api_url = process.env.VUE_APP_BASE_API_URL
	try {
		if (!image) return "";

		switch (true) {
			case image.startsWith("https://"):
				return image;
			case image.startsWith("images"):
			case image.startsWith("files"):
			case image.startsWith("admin-files"):
			case image.startsWith("templates"):
				return `${api_url}/${image}`;
			default:
				return image;
		}
	} catch (error) {
		return "";
	}
}


const isCritical = (date) => {
	// console.log(date)
  const currentDate = dayjs();
  const dateToCompare = dayjs(date)
  const timeDifference = dateToCompare.diff(currentDate, 'day')

  if (timeDifference <= 4) return true
  else return false
}

const  reduceText = (text, length, ending) => {
	if (length == null) {
		length = 100;
	}
	if (!ending) {
		ending = "...";
	}

	if (text) {
		if (text.length > length) {
			return text.substring(0, length - ending.length) + ending;
		} else {
			return text;
		}
	} else {
		return "";
	}
}


export {
  setImage,
	reduceText,
	isCritical
}