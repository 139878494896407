<template>
    <div class="page-title-area page-title-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h1>{{ pageTitle }}</h1>

                        <ul>
                            <li><router-link to="/">Accueil</router-link></li>
                            <li>{{ pageTitle }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['pageTitle'],
}
</script>