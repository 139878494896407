<template>
  <div class="footer-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
          <div class="single-footer-widget pl-5">
            <h3>À propos de nous</h3>

            <p class="text-white"><strong>TripAfrica</strong> est une plateforme digitale de réservations de séjours
              touristiques
              préconçus,
              d’hôtels, d’appartements
              saisonniers et d’achat de tickets de transports en ligne en Afrique offrant des paiements flexibles avant
              le déplacement.</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5">
            <h3>Liens utiles</h3>

            <ul class="footer-quick-links">
              <li><router-link to="/">Accueil</router-link></li>
              <li><router-link to="/trips">séjours</router-link></li>
              <li><router-link to="/contact">Contact</router-link></li>
              <li><router-link to="/about-us">À Propos</router-link></li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Contact</h3>

            <ul class="footer-contact-info">
              <li>
                <i class="ri-whatsapp-line" style="font-size: 40px;"></i>
                <span>Whatsapp</span>
                <a @click.prevent="openWhatsapp">+237 691091198</a>
              </li>
              <li>
                <i class="flaticon-email"></i>
                <span>
                  Besoin d'aide? des suggestions ?</span>
                <a href="mailto:contact@tripafrica.net">contact@tripafrica.net</a>
              </li>
              <li>
                <i class="flaticon-social-media"></i>
                <span>medias sociaux</span>

                <ul class="social">
                  <!-- <li>
                    <a href="https://twitter.com/" target="_blank"><i class="ri-twitter-x-line"></i></a>
                  </li> -->
                  <li>
                    <a href="https://facebook.com/profile.php?id=61565052564603" target="_blank"><i
                        class="ri-facebook-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/_trip_africa_?igsh=YjZncW5vanFmODJ4" target="_blank"><i
                        class="ri-instagram-line"></i></a>
                  </li>
                  <li>
                    <a href="https://x.com/TripAfrica_?t=idYUYBQdTDY1YZrzCVQ4Qw&s=09" target="_blank"><i
                        class="ri-twitter-x-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://www.tiktok.com/@tripafrica1?_t=8nqevlNe1xe&_r=1" target="_blank"><i
                        class="ri-tiktok-fill"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/tripafricaagence/" target="_blank"><i
                        class="ri-linkedin-fill"></i></a>
                  </li>
                  <!-- <li>
                    <a href="https://www.youtube.com/" target="_blank"><i class="fab fa-youtube"></i></a>
                  </li> -->
                </ul>
              </li>
            </ul>
          </div>
        </div>

      </div>

      <div class="copyright-area">
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-6 col-md-6">
            <p>
              &copy; {{ currentYear }} TripAfrica Company. Tous droits réservés
            </p>
          </div>

          <div class="col-lg-6 col-sm-6 col-md-6">
            <ul>
              <li>
                Réalisé par
                <a href="mailto:steve.wiltek@thebrains-group.com">TheBrains</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      whatsappUrl: 'https://wa.me/+237691091198?text=Bonjour, puis-je en savoir un peu plus sur les offres de Tripafrica?'

    }
  },

  methods: {
    openWhatsapp() {
      window.open(this.whatsappUrl, '_blank');
    }
  }
};
</script>