import api from "..";

const Invoice = {
  getAll: () =>
    api("GET", `/invoices`, "", "getting user invoices"),
  download: (invoice) =>
    api("POST", `/invoices/${invoice}/download`, "", "downloading invoice"),
  get: (query, data) =>
		api("GET", `/invoices?${query}`, "", `${data}`),
};

export default Invoice;
