import api from "../";
// const API_VERSION = "v1";
// const baseURL = process.env.API_URL

const Auth = {
  login: (data) =>
    api("POST", `auth/login`, data, "login user"),
  register: (data) =>
    api("POST", `auth/register`, data, "register user"),
  getToken: (data) => api("POST", `auth/request-password-token`, data, "getting password reset token"),
  resetPassword: (userId, data) => api("POST", `auth/reset-password/${userId}`, data, "reseting user password"),
};

export default Auth;
