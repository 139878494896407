<script lang="js" setup>
import { onMounted, ref } from 'vue'
import Footer from './components/Layout/Footer'
import Preloader from './components/Layout/Preloader'
import BackToTop from './components/Layout/BackToTop'
import MainNavbar from './components/Layout/MainNavbar'

/** @data */
const isLoading = ref(false)

onMounted(() => {
    setTimeout(() => {
        isLoading.value = false
    }, 2000)
})
</script>


<template>
    <div>
        <Preloader v-if="isLoading" />
        <div>
            <MainNavbar />
            <router-view />
            <Footer />
        </div>
        <BackToTop />
    </div>
</template>
