<template>
  <div class="ml-services-area gray-bg pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <span class="sub-title">NOS OFFRES PARTENAIRES</span>
        <h2>Nos Offres Booking</h2>
        <p>Réservation d’un appartement ? d’un hôtel ? d’un moyen de transport ? Retrouvez nos différentes offres
          partenaires.</p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="ml-service">
            <div class="image">
              <img src="../../assets/images/services/buses.webp" alt="image"
                style="width:100%;height:300px; object-fit: cover;">
            </div>
            <h3>
              <!-- <router-link to="/services-details">Bus</router-link> -->
              Bus
            </h3>
            <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore
              dolore magna aliqua.</p> -->
            <!-- <router-link to="/services-details" class="read-more">
                          Read More
                      </router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="ml-service">
            <div class="image">
              <img src="../../assets/images/services/hotels.webp" alt="image"
                style="width:100%;height:300px; object-fit: cover;">
            </div>
            <h3>
              <!-- <router-link to="/services-details">Hôtels</router-link> -->
              Hôtels
            </h3>
            <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore
              dolore magna aliqua.</p> -->
            <!-- <router-link to="/services-details" class="read-more">
                          Read More
                      </router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="ml-service">
            <div class="image">
              <img src="../../assets/images/services/flat.webp" alt="image"
                style="width:100%;height:300px; object-fit: cover;">
            </div>
            <h3>
              <!-- <router-link to="/services-details">Appartements</router-link> -->
              Appartements
            </h3>
            <!-- <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore
              dolore magna aliqua.</p> -->
            <!-- <router-link to="/services-details" class="read-more">
                          Read More
                      </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookingServices'
}
</script>