<script setup lang="js">
import { defineProps } from "vue"
const props = defineProps({
  height: String,
  width: String,
});
</script>
<template>
  <div class="skeleton-card" :style="{ height: props.height, width: props.width }"></div>
</template>
<style lang="scss" scoped>
@import url("@/assets/css/animation/animation.scss");

.skeleton-card {
  border-radius: 0.7rem;
  animation: skeleton-loading 0.6s linear infinite alternate;
}
</style>
