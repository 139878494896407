import axiosInstance from "../services/axios";

export default (
  method,
  url,
  data,
  msg,
  config = undefined
) => {

  switch (method) {
    case "GET":
      return new Promise((resolve, reject) => {
        axiosInstance
          .get(`${url}${data}`, config)
          .then((response) => {
            // handle success
            console.log(
              `%c Response success when ${msg}`,
              "color: #5CC62C;",
              response
            );
            resolve(response.data);
          })
          .catch((error) => {
            // handle error
            if (error.response) {
              // client received an error response (5xx, 4xx)
              console.error(`Response error when ${msg}`, error.response);

              reject(error.response);
            } else if (error.request) {
              // client never received a response, or request never left
              console.error(`Request error when ${msg}`, error.request);
              reject(error.request);
            } else {
              // anything else
              console.error(`Error when ${msg}`, error);
              reject(error);
            }
          });
      });
    case "POST":
      return new Promise((resolve, reject) => {
        axiosInstance
          .post(`${url}`, data, config)
          .then((response) => {
            // handle success
            console.log(
              `%c Response success when ${msg}`,
              "color: #5CC62C;",
              response
            );
            resolve(response.data);
          })
          .catch((error) => {
            // handle error
            if (error.response) {
              // client received an error response (5xx, 4xx)
              console.error(`Response error when ${msg}`, error.response);
              reject(error.response);
            } else if (error.request) {
              // client never received a response, or request never left
              console.error(`Request error when ${msg}`, error.request);
              reject(error.request);
            } else {
              // anything else
              console.error(`Error when ${msg}`, error);
              reject(error);
            }
          });
      });
    case "PUT":
      return new Promise((resolve, reject) => {
        axiosInstance
          .put(`${url}`, data, config)
          .then((response) => {
            // handle success
            console.log(
              `%c Response success when ${msg}`,
              "color: #5CC62C;",
              response
            );
            resolve(response.data);
          })
          .catch((error) => {
            // handle error
            if (error.response) {
              // client received an error response (5xx, 4xx)
              console.error(`Response error when ${msg}`, error.response);
              reject(error.response);
            } else if (error.request) {
              // client never received a response, or request never left
              console.error(`Request error when ${msg}`, error.request);
              reject(error.request);
            } else {
              // anything else
              console.error(`Error when ${msg}`, error);
              reject(error);
            }
          });
      });
    case "DEL":
      return new Promise((resolve, reject) => {
        axiosInstance
          .delete(`${url}${data}`)
          .then((response) => {
            // handle success
            console.log(
              `%c Response success when ${msg}`,
              "color: #5CC62C;",
              response
            );
            resolve(response.data);
          })
          .catch((error) => {
            // handle error
            if (error.response) {
              // client received an error response (5xx, 4xx)
              console.error(`Response error when ${msg}`, error.response);
              reject(error.response);
            } else if (error.request) {
              // client never received a response, or request never left
              console.error(`Request error when ${msg}`, error.request);
              reject(error.request);
            } else {
              // anything else
              console.error(`Error when ${msg}`, error);
              reject(error);
            }
          });
      });
    default:
      return new Promise((resolve) => {
        resolve({});
      });
  }
};
