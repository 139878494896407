<template>
    <div>
        <PageTitle pageTitle="Page de connexion" />
        <LoginContent />
    </div>
</template>

<script>
import PageTitle from '../Common/PageTitle'
import LoginContent from '../Login/LoginContent'

export default {
    components: {
        PageTitle,
        LoginContent,
    }
}
</script>