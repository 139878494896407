import axios from "axios";
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json, text/plain",
  },
});
axiosInstance.defaults.headers.common["Authorization"] = "";

axiosInstance.interceptors.response.use((res)=>res,
	async (error) => {
		// all the error responses
		if (error.response) {
			switch (error.response.status) {
				case 400:
					console.error(error.response.status, error.message);
					break;

				case 401:
					window.location.href = `/login`;
					break;

				case 403: // Authentification error
					console.error(error.response.status, error.message);
					break;

				case 404: // Not found
					console.error(error.response.status, error.message);
					break;

				case 498: // Token expired
           window.location.href = `/login`;
					break;

				case 499: // Refresfh token expired
        window.location.href = `/login`;
					break;

				default:
					console.error(error.message);
			}
		}
		// Handle network error

		return Promise.reject(error);
	}
);

export default axiosInstance;
