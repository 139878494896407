<script lang="js" setup>
import { onMounted } from "vue"
import { storeToRefs } from "pinia"
import PageTitle from '@components/Common/PageTitle'
import { useArticleStore } from "@/store/articleStore"
import LTripCard from "@components/trip-africa/trips/LTripCard"
import VSkeletonLine from "@components/trip-africa/skeleton/VSkeletonLine"
import VSkeletonBlock from "@components/trip-africa/skeleton/VSkeletonBlock"


/** @data */
const articleStore = useArticleStore()
const { articles, isLoading } = storeToRefs(articleStore)



/**@hooks */
onMounted(() => {
    articleStore.loadArticles().catch((err) => console.log(err))
})


</script>


<template>
    <div class="page">
        <PageTitle pageTitle="Séjours" />

        <div class="container" v-if="isLoading">
            <div class="row my-5">
                <div class="col-lg-4 col-md-6 col-sm-6" v-for="item in new Array(6)" :key="item">
                    <VSkeletonBlock class="my-2" width="100%" height="300px" />
                    <VSkeletonLine width="90%" />
                    <VSkeletonLine class="mt-1" width="70%" />
                </div>
            </div>
        </div>


        <div class="shop-area ptb-100" v-else>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mb-4" v-for="trip in articles" :key="trip.id">
                        <LTripCard :trip="trip" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.loading {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
