import { defineStore } from 'pinia'
import { QueryBuilder} from "@/class/queryBuilder"
import Articles from "@/api/models/Articles";

/** @data */

export const useArticleStore = defineStore('articleStore', {
  state: () => ({ 
    article: [],
    articles: [], 
    isLoading: false, 
    loadingDetails: false, 
    articlesDetailsList: [],
}),
  getters: {
    getArticles: (state) => state.articles,
  },
  actions: {
    async loadArticles() {
        return new Promise((resolve, reject) => {
            if (this.articles.length > 0) {
                resolve(this.articles);
                return;
            }

            this.isLoading = true;
            Articles.get()
                .then((res) => {
                    this.articles = res.data.articles;
                    this.isLoading = false
                    resolve(this.articles);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                })
        });
    },

    async loadArticle(id) {
        return new Promise((resolve, reject) => {
            if (this.articlesDetailsList.length > 0 && this.isCached(id)) {
                return resolve(this.article = this.isCached(id))
            }

            
            const query = new QueryBuilder()
                .filter('slug', 'eq', id)
                .include(['medias'])
                .build()

            this.loadingDetails = true;
            Articles.getQuery(query, 'getting specific article')
                .then((res) => {
                    this.article = res.data.articles[0];
                    this.loadingDetails = false
                    this.articlesDetailsList.push(this.article)
                    resolve(this.article);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                })
        });
    },

    isCached(id){
        return this.articlesDetailsList.find((article)=> article?.id == id)
    }


},
})