<script lang="js" setup>
import { ref } from "vue"
import * as yup from 'yup';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from "vue-router"
import { useUserStore } from '@/store/userStore';
import { createToaster } from "@meforma/vue-toaster";
import VLoader from '@components/trip-africa/VLoader.vue';
import { Field, Form, ErrorMessage } from 'vee-validate';



const route = useRoute()
const router = useRouter()
const minDate = new Date();
const hasErrors = ref(false)
const showPassword = ref(false)
const userStore = useUserStore()
const { register } = userStore
const validationErrors = ref({})
const toaster = createToaster();
const { loading } = storeToRefs(userStore)


const schema = yup.object({
    first_name: yup.string().required("votre prenom est requis"),
    last_name: yup.string().required("votre nom est requis"),
    username: yup.string().required("votre nom d'utilisateur  est requis"),
    birthday: yup.date().required("votre date de naissance est requise").max(minDate, "votre date de naissance semble incorrecte"),
    email: yup.string().required("votre email est requis").email("votre email n'est pas valide"),
    sex: yup.string().required('votre sexe est requis').oneOf(['M', 'F'], 'la valeur du sex erst incorrecte'),
    phone: yup.number().required("votre numéro de téléphone est requis").min(9, "votre numéro de téléphone n'est pas valide"),
    password: yup.string().required("votre mot de passe est requis").min(6, "votre numéro de téléphone doit contenir plus neuf caractères"),
});

const authRegister = (values) => {
    console.log(values)
    register(values)
        .then(() => {
            if (route.query.next) router.replace({ path: route.query.next })
            else router.push('/')
            toaster.success("content de vous revoir");
        })
        .catch((err) => {
            console.log(err.data.data)

            if (err.status == 422) {
                hasErrors.value = true
                validationErrors.value = err.data.data
            }
            if (err.status == 403) {
                toaster.warning("email ou mot de passe incorrect.");
            } else {
                toaster.error("Une erreur est survenue lors du proccessus d'authentication.");
            }
        })
}
</script>


<template>
    <div class="ptb-100">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6">

                    <div class="alert alert-danger d-flex align-items-center" role="alert" v-if="hasErrors">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                            <use xlink:href="#exclamation-triangle-fill" />
                        </svg>
                        <div>
                            <ul>
                                <li v-for="error in validationErrors" :key="error">{{ error[0] }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="form-content">
                        <div class="form-header">
                            <h3>Inscription</h3>
                        </div>

                        <Form @submit="authRegister" :validation-schema="schema">
                            <div class="form-group">
                                <label>Prenom</label>
                                <Field type="text" name="first_name" class="form-control" id="first-name"
                                    placeholder="Prenom" />
                                <ErrorMessage class="text-danger" name="first_name" />
                            </div>
                            <div class="form-group">
                                <label>Nom</label>
                                <Field type="text" name="last_name" class="form-control" id="last-name"
                                    placeholder="Nom" />
                                <ErrorMessage class="text-danger" name="last_name" />
                            </div>
                            <div class="form-group">
                                <label>Nom d'utilisateur</label>
                                <Field type="text" name="username" class="form-control" id="user-name"
                                    placeholder="Nom d'utilisateur" />
                                <ErrorMessage class="text-danger" name="username" />
                            </div>
                            <div class="form-group">
                                <label>Date de naissance</label>
                                <Field type="date" name="birthday" class="form-control" id="dob"
                                    placeholder="mm/dd/yyyy" />
                                <ErrorMessage class="text-danger" name="birthday" />
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <Field type="email" name="email" class="form-control" id="your-email"
                                    placeholder="joe@tripafrica.fr" />
                                <ErrorMessage class="text-danger" name="email" />
                            </div>
                            <div class="form-group">
                                <label>Téléphone</label>
                                <Field type="text" name="phone" class="form-control" id="your-phone"
                                    placeholder="6XXXXXXXXXX" />
                                <ErrorMessage class="text-danger" name="phone" />
                            </div>
                            <div class="form-group">
                                <div class="form-check form-check-inline">
                                    <Field class="form-check-input" type="radio" id="inlineRadio1" value="M"
                                        name="sex" />
                                    <label class="form-check-label" for="inlineRadio1">Homme</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <Field class="form-check-input" type="radio" id="inlineRadio2" value="F"
                                        name="sex" />
                                    <label class="form-check-label" for="inlineRadio2">Femme</label>
                                </div>
                                <ErrorMessage class="text-danger" name="sex" />
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <Field :type="showPassword ? 'text' : 'password'" class="form-control"
                                    id="your-password" name="password" placeholder="p******" />
                                <ErrorMessage class="text-danger" name="password" />
                            </div>
                            <div class="form-group">
                                <b-form-checkbox id="checkbox-1" v-model="showPassword" name="checkbox-1"
                                    value="accepted" unchecked-value="not_accepted">
                                    Afficher le mot de passe
                                </b-form-checkbox>
                            </div>
                            <button type="submit" :disabled="loading" class="default-btn">Inscription</button>
                            <div style="margin-top: 1rem;" v-if="loading">
                                <VLoader />
                            </div>
                        </Form>

                        <div class="form-footer">
                            <p>Do you have an account? <router-link class="form-link"
                                    :to="{ path: '/login', query: { ...$route.query } }">Login</router-link>
                            </p>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-lg-6">
                    <img src="../../assets/images/signup.jpg" alt="Images">
                </div> -->
            </div>
        </div>
    </div>
</template>