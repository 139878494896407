<script setup lang="js">
import { defineProps } from "vue"
const props = defineProps({
  width: String,
  height: {
    type: String,
    required: false
  },
})
//props
</script>
<template>
  <div class="line" :style="{ width: props.width, height: props.height ? props.height : '1rem' }"></div>
</template>
<style lang="scss" scoped>
@import url("@/assets/css/animation/animation.scss");

.line {
  border-radius: 1rem;
  animation: skeleton-loading 0.6s linear infinite alternate;
}
</style>
