<template>
    <div class="subscribe-area bg-F4F7FC">
        <div class="subscribe-inner-area lets-work">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <span class="sub-title">avec TRIPAFRICA</span>
                        <h2>DECOUVREZ lA VRAIE AFRIQUE!</h2>
                    </div>

                    <div class="col-lg-6">
                        <div class="contact-btn">
                            <router-link to="/contact" class="default-btn">
                                Contactez nous <span></span>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LetsGetToWork'
}
</script>