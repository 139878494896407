import api from "../";
// const API_VERSION = "v1";
// const baseURL = process.env.API_URL

const Articles = {
  get: () =>
    api("GET", `articles`, "", "getting all articles"),
  getQuery: (query, data) =>
		api("GET", `/articles?${query}`, "", `${data}`),
  getArticle: (id) =>
    api("GET", `articles/${id}`, "", "getting  article "+id),
};

export default Articles;
