import { defineStore } from 'pinia'
import Invoice from "@/api/models/Invoice";
import { QueryBuilder} from "@/class/queryBuilder"
import { useUserStore } from "@/store/userStore";


/** @data */
export const useInvoiceStore = defineStore('invoice', {
  state: () => ({ invoices: [], invoice: null,  transactions: [], loadingInvoices: false }),
  actions: {
    async loadInvoices() {

      const user = useUserStore().user

      const query = new QueryBuilder()
      .sort([{ key: 'updated_at', direction: 'DESC' }])
      .filter('user_id', 'eq', user?.id)
      .include(['transactions', 'article'])
      .build()

      return new Promise((resolve, reject) => {

        if(this.invoices.length > 0) return resolve(this.invoices)

        this.loadingInvoices = true;
        Invoice.get(query, "getting user invoices with transactions")
            .then((res) => {
              resolve(res);
              console.log(res)
              this.loadingInvoices = false
              this.invoices = res.data.invoices;
            })
            .catch((err) => {
                reject(err);
                console.log(err); 
            })
      });
    },
    async getInvoice(invoiceID) {

      this.invoice = null
      const user = useUserStore().user

      const query = new QueryBuilder()
      .sort([{ key: 'updated_at', direction: 'DESC' }])
      .filter('id', 'eq', invoiceID)
      .filter('user_id', 'eq', user)
      .include(['transactions', 'article'])
      .build()

      return new Promise((resolve, reject) => {

        if(this.invoices.length > 0) {
          const invoice = this.invoices.find((invoice) => invoice.id === invoiceID)
          if(invoice){ 
            this.transactions = invoice?.transactions
            this.invoice = invoice
            return resolve(invoice?.transactions)
          }
        }

        console.log(this.invoice)

        this.loadingInvoices = true;
        Invoice.get(query, "getting user invoices with transactions")
            .then((res) => {
              console.log(res)
              this.loadingInvoices = false
              this.transactions = res.data.invoices[0].transactions;
              this.invoice = res.data.invoices[0];
              resolve(this.transactions)
            })
            .catch((err) => {
                reject(err);
                console.log(err); 
            })
      });
    },
},
})