<template>
  <div class="feedback-slides">
    <Carousel :autoplay="5000" :settings="settings" :breakpoints="breakpoints" :wrap-around="true">
      <Slide v-for="slide in carouselItems" :key="slide.id">
        <div class="single-feedback-item">
          <p>{{ slide.description }}</p>

          <div class="client-info">
            <!-- <img :src="slide.image" alt="image" /> -->

            <h3>{{ slide.name }}</h3>
            <!-- <span>{{ slide.position }}</span> -->
          </div>
        </div>
      </Slide>

      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "FeedbackCarouselTwo",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselItems: [
      {
        id: 1,
        description:
          "“Nous avons passé un séjour de vacances parfait. Le cadre était magnifique, le logement était spacieux et confortable, et la vue était imprenable. Le personnel était très accueillant et attentionné.Nous avons profité pleinement de notre séjour.”",
        image: require("../../assets/images/clients/client-1.jpg"),
        name: "Timba Alfred",
        position: "Manager",
      },
      {
        id: 2,
        description:
          "Le séjour que nous avons passé est et restera inoubliable. Le circuit était parfaitement organisé et nous avons pu découvrir les plus beaux sites de la région. L'itinéraire était bien pensé et nous avons eu le temps de profiter de chaque destination.”",
        image: require("../../assets/images/clients/client-2.jpg"),
        name: "Nkenfack Anne Marie",
        position: "Web Developer",
      },
      {
        id: 3,
        description:
          "“On a passé des vacances organisées au top !TripAfrica a tout prévu pour que nous puissions profiter pleinement de notre séjour.Le logement était parfait, le transport était confortable et les activités étaient variées et intéressantes.”",
        image: require("../../assets/images/clients/client-3.jpg"),
        name: "Hayatou Idrissou",
        position: "Designer",
      },
      {
        id: 4,
        description:
          "“We had had the most perfect holiday stay with TripAfrica.The accommodation was absolutely stunning. The location was also ideal, with magnificent landscapes and breathtaking sunsets!.The staff were incredibly friendly and helpful, and they went out of their way to make our stay as enjoyable as possible.”",
        image: require("../../assets/images/clients/client-4.jpg"),
        name: "Achu John Michael",
        position: "Manager",
      },
      // {
      //   id: 5,
      //   description:
      //     "“We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.”",
      //   image: require("../../assets/images/clients/client-5.jpg"),
      //   name: "Tom Nessham",
      //   position: "EnvyTheme",
      // },
    ],
    breakpoints: {
      0: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      768: {
        itemsToShow: 2,
        snapAlign: "center",
      },
      1200: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      1550: {
        itemsToShow: 4,
        snapAlign: "center",
      },
    },
  }),
});
</script>