<script lang="js" setup>
import dayjs from "dayjs"
import * as yup from 'yup';
import { setImage } from "@/utils"
import { storeToRefs } from "pinia"
import { isCritical } from "@/utils"
import { useUserStore } from "@/store/userStore";
import { useRoute, useRouter } from "vue-router";
import Transaction from "@/api/models/Transaction";
import { createToaster } from "@meforma/vue-toaster";
import { useArticleStore } from "@/store/articleStore";
import { useInvoiceStore } from '@/store/invoiceStore';
import { Field, Form, ErrorMessage } from 'vee-validate';
import VLoader from '@components/trip-africa/VLoader.vue';
import { ref, watch, computed, onMounted, onUnmounted } from "vue"


/**@vars */

const { getInvoice } = useInvoiceStore()
const { invoice, invoices } = storeToRefs(useInvoiceStore())


const { loadArticle } = useArticleStore()
const { loadingDetails } = storeToRefs(useArticleStore())

const userStore = useUserStore();
const { getUser } = storeToRefs(userStore)

const date = ref("")
const article = ref({})
const oparator = ref('')
const route = useRoute()
const router = useRouter()
const transactionID = ref('');
const toaster = createToaster();
const validationErrors = ref([])
const transactionFailed = ref(false)
const transactionPending = ref(false)
const personDetails = ref({ ...getUser.value, qty: 1, nocouples: 0, departure: null })

const departureDates = computed(() => {
  return article.value?.departures?.filter((d) => {
    if (dayjs(d).diff(dayjs(), 'day') > 4) {
      return d
    }
  })
})


const getSchema = computed(() => {
  if (!invoice.value) {
    return yup.object().shape({
      name: yup.string().required("votre nom est requis"),
      address: yup.string().required("votre adresse est requise"),
      amount: yup.number("Le montant à payer est obligatoire").required("Le montant à payer est obligatoire"),
      departure: yup.date("La date de départ  est obligatoire").required("La date de départ  est obligatoire"),
      email: yup.string().required("votre email est requis").email("votre email n'est pas valide"),
      phone: yup.number().required("votre numéro de téléphone est requis").min(9, "votre numéro de téléphone n'est pas valide"),
      qty: yup.number(),
      nocouples: yup.number(),
    });
  }

  return yup.object().shape({
    amount: yup.number().min(50).required("Le montant à payer est obligatoire"),
  });
})

const getPrice = computed(() => {
  let couplePrice = 0
  if (article.value?.couple_price && personDetails.value.nocouples) {
    couplePrice = article.value?.couple_price * personDetails.value.nocouples
  }
  return (article.value?.price * personDetails.value.qty) + couplePrice
})


/**@func */
watch(
  () => loadingDetails.value,
  (newVal, oldVal) => {
    if (newVal == false && oldVal == true) {
      setTimeout(() => {
        date.value = article.value?.departures[0]
      }, 400)
    }

  }
)

watch(
  () => personDetails.value.qty,
  (newVal) => {
    if (newVal < 0) personDetails.value.qty = 0
    if (newVal == 0 && personDetails.value.nocouples == 0) {
      personDetails.value.nocouples = 1
    }

  }
)
watch(
  () => personDetails.value.nocouples,
  (newVal) => {
    console.log(personDetails.value.nocouples)
    if (newVal < 0) personDetails.value.nocouples = 0
    if (newVal == 0 && personDetails.value.qty == 0) {
      personDetails.value.qty = 1
    }
  }
)




const initTransaction = (values) => {

  validationErrors.value = []

  if (getPrice.value == 0) {
    toaster.warning("le moment du payment ne peux être null")
    return
  }

  if (!invoice.value && !article.value?.departures.includes(values?.departure)) {
    toaster.warning("cette date n'est pas disponible pour ce voyage !")
    return
  }





  if (oparator.value == "") return toaster.warning("selectionner un operateur pour la transaction!")
  const name = values.first_name + " " + values.last_name

  const dataToSend = {
    name,
    email: values.email,
    phone: values.phone,
    amount: values.amount,
    user_id: getUser.value.id,
    departure: values?.departure,
    article_id: route.params?.trip,
    individual_tickets: values?.qty,
    couple_tickets: values?.nocouples
  }

  if (parseInt(dataToSend.amount) > parseInt(article.value.price)) {
    toaster.warning("le montant fourni excède le montant total facturable pour ce séjour")
    return
  }

  if (invoice.value) {

    dataToSend.qty = invoice.value.qty
    dataToSend.invoice_id = invoice.value?.id
    dataToSend.departure = invoice.value.departure

    if (parseInt(invoice.value.amount) + parseInt(dataToSend.amount) > parseInt(article.value.price)) {
      toaster.warning("le montant fourni excède le prix total facturable pour ce séjour")
      return
    }
  }

  transactionPending.value = true
  transactionFailed.value = false

  Transaction.init(oparator.value, dataToSend)
    .then((res) => {
      transactionID.value = res.data.transaction_id
      checkTransaction()
    })
    .catch((err) => {
      validationErrors.value = Object.values(err.data.data);
      toaster.error("Une erreur est survenue lors de la transaction")
      transactionFailed.value = true
      transactionPending.value = false
    })
}

const checkTransaction = () => {

  Transaction.check(oparator.value, { transaction_id: transactionID.value })
    .then((res) => {

      if (['SUCCESSFULL', 'SUCCESSFUL'].includes(res.data.transaction_status)) {
        invoices.value = []
        router.push('/profile')
        transactionPending.value = false
        toaster.success("Le paiement effectué")
      }

      else if (res.data.transaction_status == "CANCELLED") {
        transactionPending.value = false
        toaster.error("La transaction a été annulée")
      }

      else if (res.data.transaction_status == "PENDING") {
        checkTransaction()
      }
    }).catch((err) => {
      console.log(err)
      toaster.error("Le paiement à échoué, veuillez réessayer plus tard")
    })

}

const loadInvoice = (invoiceId) => {
  getInvoice(invoiceId)
    .then((res) => console.log(res))
    .catch((err) => console.log(err))
}



onMounted(() => {
  personDetails.value = { ...getUser.value, ...personDetails.value }
  loadArticle(route.params?.trip)
    .then((res) => article.value = res)
    .catch((err) => console.log(err))

  if (route.query.invoice) {
    const invoiceId = route.query.invoice?.toString()
    loadInvoice(invoiceId)
  }
})

onUnmounted(() => {
  invoice.value = null
})

</script>

<template>
  <div class="checkout-area ptb-100">
    <div class="container">

      <Form @submit="initTransaction" :initial-values="personDetails" :validation-schema="getSchema">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="billing-details">
              <h3 class="title">Details du sejour</h3>

              <div class="row">


                <div class="order-details" v-if="!loadingDetails">

                  <img :src="setImage(article?.photo)" class="article-img">

                  <div class="order-table table-responsive">
                    <h4>
                      <router-link :to="{ name: 'trip-detail', params: { id: article?.id ?? 1 } }">
                        {{ article?.name }}
                      </router-link>
                    </h4>
                    <h5> {{ getPrice }} FCFA TTC</h5>
                    <p style="margin: unset"> {{ article?.duration }} FCFA TTC</p>
                    <p style="margin: unset"> {{ article?.destination }}</p>
                    <p style="margin: unset"> Inclus: </p>
                    <ul>
                      <li v-for="p in article?.packages" :key="p.id">{{ p }}</li>
                    </ul>
                  </div>
                </div>
                <div v-else>
                  <VLoader />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-12">

            <div class="alert alert-danger" v-if="validationErrors.length > 0">
              <ul>
                <li v-for="(error, i) in validationErrors" :key="i">{{ error[0] }}</li>
              </ul>
            </div>

            <div class="order-details"
              v-if="article?.departures ? !isCritical(article?.departures[article?.departures?.length - 1]) : true">
              <h3 class="title">Details du paiement</h3>
              <div class="payment-box mt-0">
                <div class="payment-method">

                  <div class="alert alert-danger d-flex align-items-center" role="alert"
                    v-if="invoice && isCritical(invoice?.departure)">
                    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                      <use xlink:href="#exclamation-triangle-fill" />
                    </svg>
                    <div>
                      <p class="m-0">Vous avez depasser le delai de paiment pour ce séjour.</p>
                      <p class="m-0">Veuillez contacter le service client pour plus d''indications</p>
                    </div>
                  </div>

                  <div v-if="invoice && !isCritical(invoice?.departure)"
                    class="alert alert-info d-flex align-items-center" role="alert">
                    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                      <use xlink:href="#exclamation-triangle-fill" />
                    </svg>
                    <div>
                      <p class="m-0">vous avez un payement non terminé pour ce séjour</p>
                      <p class="m-0">reste à payer: {{ invoice?.remaining_amount }} FCFA</p>
                    </div>
                  </div>

                  <div v-if="invoice ? !isCritical(invoice?.departure) : true">

                    <div class="col-lg-12 col-md-6">
                      <div class="form-group">
                        <label>Nom complet<span class="required text-danger">*</span></label>
                        <Field name="name" type="text" id="fullName" class="form-control" />
                        <ErrorMessage class="text-danger" name="name" />
                      </div>
                    </div>

                    <div class="col-lg-12 col-md-6">
                      <div class="form-group">
                        <label>Adresse <span class="required text-danger">*</span></label>
                        <Field name="address" type="text" id="address" class="form-control" />
                        <ErrorMessage class="text-danger" name="address" />
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-lg-6 col-12">
                        <div class="form-group">
                          <label>Email <span class="required text-danger">*</span></label>
                          <Field name="email" type="email" id="email" class="form-control" />
                          <ErrorMessage class="text-danger" name="email" />
                        </div>
                      </div>

                      <div class="col-lg-6 col-12">
                        <div class="form-group">
                          <label>Numero de téléphone <span class="required text-danger">*</span></label>
                          <Field name="phone" type="text" id="phone" class="form-control" />
                          <ErrorMessage class="text-danger" name="phone" />
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-lg-6 col-12">
                        <div class="form-group">
                          <label>Montant à payer (FCFA) <span class="required text-danger">*</span></label>
                          <Field v-if="article?.payment_slices" name="amount" as="select" id="departure"
                            class="form-control">
                            <option :value="slice" v-for="(slice, index) in article?.payment_slices" :key="index">
                              {{ slice }}
                            </option>
                          </Field>
                          <Field v-else name="amount" type="number" id="amount" class="form-control" />
                          <ErrorMessage class="text-danger" name="amount" />
                        </div>
                      </div>

                      <div class="col-lg-6 col-12" v-if="invoice == null">
                        <div class="form-group">
                          <label>Date de départ <span class="required text-danger">*</span></label>
                          <Field v-model="date" name="departure" as="select" id="departure" class="form-control">
                            <option :value="departure" v-for="(departure, index) in departureDates" :key="index">
                              {{ dayjs(departure, 'YYYY-MMM-DD').format("ddd DD MMM YYYY") }}
                            </option>
                          </Field>
                          <ErrorMessage class="text-danger" name="departure" />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">

                      <div class="col-lg-6 col-12" v-if="invoice == null">
                        <div class="form-group">
                          <label>Nombre de voyageurs <span class="required text-danger">*</span></label>
                          <Field name="qty" v-model="personDetails.qty" type="number" id="qty" class="form-control"
                            min="0" />
                          <ErrorMessage class="text-danger" name="qty" />
                        </div>
                      </div>

                      <div class="col-lg-6 col-12" v-if="invoice == null">
                        <div class="form-group">
                          <label>Nombre de couples</label>
                          <Field name="nocouples" v-model="personDetails.nocouples" type="number" min="0"
                            id="couple_qty" class="form-control" />
                          <ErrorMessage class="text-danger" name="nocouples" />
                        </div>
                      </div>
                    </div>

                    <div class="d-flex mt-3">
                      <p>
                        <Field type="radio" v-model="oparator" value="om" id="paypal" name="radio-group" />
                        <label for="paypal">
                          <img src="@/assets/images/logo/OM.jpeg" class="logo-img" alt="orange money logo">
                        </label>
                      </p>
                      <p style="margin-left: 24px;">
                        <Field type="radio" v-model="oparator" value="momo" id="cash-on-delivery" name="radio-group" />
                        <label for="cash-on-delivery">
                          <img src="@/assets/images/logo/MOMO.png" class="logo-img" alt="mtn mobile money logo">
                        </label>
                      </p>
                    </div>

                  </div>

                </div>

                <div class="d-flex" style="gap: 10px">
                  <button type="submit" class="default-btn order-btn"
                    v-if="invoice ? !isCritical(invoice?.departure) : true">
                    {{ invoice ? 'continuer le payement' : 'payer' }}
                  </button>
                </div>

              </div>
              <div v-if="transactionPending">
                <VLoader />
              </div>
            </div>

            <div class="alert alert-danger d-flex align-items-center" role="alert"
              v-if="article?.departures ? isCritical(article?.departures[article?.departures?.length - 1]) : true">
              <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                <use xlink:href="#exclamation-triangle-fill" />
              </svg>
              <div>
                <p class="m-0">Le delai de paiment pour ce séjour est depassé.</p>
                <p class="m-0">
                  <router-link to="/contact">Veuillez contacter le service client pour plus
                    d'indications</router-link>
                </p>
              </div>
            </div>
          </div>

        </div>
      </Form>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.article-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 1rem;
  margin: 1rem 0;
}

.logo-img {
  height: 60px;
  width: 120px;
}
</style>