<template>
    <div>
        <PageTitle pageTitle="Inscription" />
        <SignupContent />
    </div>
</template>

<script>
import PageTitle from '../Common/PageTitle'
import SignupContent from '../Signup/SignupContent'

export default {
    components: {
        PageTitle,
        SignupContent,
    }
}
</script>