<template>
    <div>
        <PageTitle pageTitle="Contact" />
        <ContactContent />
    </div>
</template>

<script>
import PageTitle from '../Common/PageTitle'
import ContactContent from '../Contact/ContactContent'

export default {
    components: {
        PageTitle,
        ContactContent,
    }
}
</script>