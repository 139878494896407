import { defineStore } from 'pinia'
import Auth from "@/api/models/Auth";
import User from "@/api/models/User";

/** @data */

export const useUserStore = defineStore('__usr__', {
  state: () => ({ user: null, loading: false, error: false, app_key: null }),
  getters: {
    getUser: (state) => state.user,
    isAuth: (state) => state.app_key != null,
    getToken: (state) => state.app_key,
  },
  actions: {
    async login(payload) {
        return new Promise((resolve, reject) => {
          this.loading = true;
          Auth.login(payload)
              .then((res) => {
                resolve(res);
                this.user = res.data.user;
                this.app_key = res.data.token
              })
              .catch((err) => {
                  reject(err);
                  console.log(err);
                  this.error = false
              })
              .finally(() => this.loading = false)
        });
    },
    async register(payload) {
        return new Promise((resolve, reject) => {
          this.loading = true;
          Auth.register(payload)
              .then((res) => {
                resolve(res);
                this.user = res.data.user;
                this.app_key = res.data.token
              })
              .catch((err) => {
                  reject(err);
                  console.log(err);
                  this.error = false
              })
              .finally(() => this.loading = false)
        });
    },
    async updateUser(payload) {
        return new Promise((resolve, reject) => {
          this.loading = true;
          User.update(payload, this.user.id)
              .then((res) => {
                resolve(res);
                this.user = res.data.user;
              })
              .catch((err) => {
                  reject(err);
                  console.log(err);
                  this.error = false
              })
              .finally(() => this.loading = false)
        });
    },

    logout(){
      this.user = null,
      this.app_key = null
    }
},
persist: true
})