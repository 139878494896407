<script lang="js" setup>
import dayjs from "dayjs"
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from "vue-router"
import Invoice from "@/api/models/Invoice";
import PageTitle from '@components/Common/PageTitle'
import { createToaster } from "@meforma/vue-toaster";
import { useInvoiceStore } from '@/store/invoiceStore';
import VLoader from '@components/trip-africa/VLoader.vue';

/** @vars */
const invoinceStore = useInvoiceStore()
const { getInvoice } = invoinceStore

const route = useRoute()
const toaster = createToaster();
const { loadingInvoices, transactions, invoice } = storeToRefs(invoinceStore)

/** @func */

const getInvoicetransaction = (id) => {
  getInvoice(id).catch((err) => console.log(err))
}

const downloadInvoice = (invoiceId) => {
  toaster.warning("le téléchargement débutera dans quelque instant..");
  const API_URL = process.env.VUE_APP_BASE_API_URL

  Invoice.download(invoiceId)
    .then((res) => {
      console.log(res)
      const filePath = res.data.file_link;
      window.open(`${API_URL}/${filePath}`, "_blank");
    })
    .catch((err) => {
      console.log(err)
      toaster.error("une erreur est survenue  lors du telechargement de la facture !");
    })


}

onMounted(() => {
  getInvoicetransaction(route.params.invoice.toString())
})
</script>

<template>
  <div class="page">
    <PageTitle pageTitle="Transactions " />

    <div class="container ptb-100">
      <div class="row justify-content-center">
        <div v-if="loadingInvoices">
          <VLoader />
        </div>
        <div v-else>
          <h3>net à payer : {{ invoice?.qty * invoice?.article.price }} FCFA</h3>
          <h5>voyage: <router-link :to="{ name: 'trip-detail', params: { id: invoice?.article_id ?? 1 } }"> {{
            invoice?.article?.name }} </router-link></h5>

          <h5>Date de départ: {{ dayjs(invoice?.departure, 'YYYY-MMM-DD').format("dddd le DD , MMMM YYYY") }}</h5>

          <h5>Nombre de voyageurs: {{ invoice?.qty }}</h5>

          <h5>reste à payer: {{ invoice?.remaining_amount }} FCFA</h5>

          <h5>
            status :
            <span :class="invoice?.status == 'incomplete' ? 'text-warning' : 'text-success'">
              {{ invoice?.status == 'incomplete' ? 'non terminée' : 'terminée' }}
            </span>
          </h5>

          <div class="d-flex mb-4 ">
            <router-link v-if="invoice?.remaining_amount > 0"
              :to="{ name: 'checkout', params: { trip: invoice?.article?.id }, query: { invoice: invoice?.id } }"
              class="btn btn-success btn-sm" style="margin-right: 1rem;">
              Nouveau payement
            </router-link>

            <button class="btn btn-success btn-sm" @click="downloadInvoice(invoice?.id)"> Télécharger la
              facture</button>

          </div>


          <table class="table caption-top" v-if="transactions.length > 0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Montant</th>
                <th scope="col">Operateur</th>
                <th scope="col">Payeur</th>
                <th scope="col">Date de la transaction</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction, index) in transactions" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ transaction?.amount }} FCFA</td>
                <td>{{ transaction?.operator == 'OM' ? 'Orange Money' : 'Mobile Money' }}</td>
                <td>
                  <small>{{ transaction?.payer_name }}</small><br />
                  <small> {{ transaction?.payer_email }}</small>
                </td>
                <td>{{ dayjs(transaction?.created_at, 'YYYY-MMM-DD').format("ddd DD MMM YYYY à HH:MM") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
