import httpBuildQuery from "http-build-query";

// type Direction = "ASC" | "DESC";
// type Oparator = "ct" | "sw" | "ew" | "eq" | "gt" | "gte" | "lte" | "lt" | "in" | "bt";

// interface Sort {
// 	key: string;
// 	direction: Direction;
// }

// interface Filter {
// 	key: string;
// 	value: string;
// 	not?: boolean;
// 	operator: Oparator;
// }

// interface FilterGroup {
// 	filters: Filter[];
// 	or?: boolean;
// }

// interface WhereHas {
// 	relationship: string;
// 	key: string;
// 	value: string;
// 	operator: Oparator;
// }

// interface Load {
// 	relationship: string;
// 	key: string | undefined;
// 	direction: string | undefined;
// 	limit: number | undefined;
// }

// interface Query {
// 	filter_groups: FilterGroup[];
// 	includes?: string[];
// 	limit?: number;
// 	start?: number;
// 	page?: number;
// 	select?: string[];
// 	sort?: Sort[];
// 	group_by?: string[] | string;
// 	wherehas?: WhereHas[];
// 	load?: Load[];
// }

export class QueryBuilder {
	constructor() {
		this.query = {
			filter_groups: [{ filters: [] }],
		};
	}

	addGroup(or = false) {
		if (!this.query.filter_groups) this.query.filter_groups = [];
		this.query.filter_groups.push({ filters: [], or: `${or}` });
		return this;
	}

	filter(key, operator, value, not = false) {
		const filterGroup = { key, operator, value, not };
		if (!this.query.filter_groups[0].filters) this.query.filter_groups[0].filters = [];
		this.query.filter_groups[0].filters.push(filterGroup);
		return this;
	}

	include(include) {
		if (!this.query.includes) this.query.includes = [];
		// this.query.includes = this.query.includes.concat(include);
		this.query.includes = include;
		return this;
	}

	limit(limit) {
		this.query.limit = limit;
		return this;
	}

	start(start) {
		this.query.start = start;
		return this;
	}

	page(page) {
		this.query.page = page;
		return this;
	}

	select(keys) {
		if (!this.query.select) this.query.select = [];
		this.query.select = this.query.select.concat(keys);
		return this;
	}

	sort(sort) {
		this.query.sort = sort;
		return this;
	}

	wherehas(
		relationship,
		key,
		operator,
		value,
		not = false
	) {
		const filter = { relationship, key, operator, value, not };
		if (!this.query.wherehas) this.query.wherehas = [];
		this.query.wherehas.push(filter);
		return this;
	}

	load(relationship, limit=undefined, key=undefined, direction=undefined) {
		const loadPayload = { relationship, limit, key, direction };
		if (!this.query.load) this.query.load = [];
		this.query.load.push(loadPayload);
		return this;
	}

	build() {
		return httpBuildQuery(this.query);
	}

	groupBy(keys) {
		this.query.group_by = keys;
		return this;
	}

	reset() {
		this.query = {
			filter_groups: [{ filters: [] }],
		};
		return this.query;
	}
}
